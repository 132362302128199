exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investment-highlights-tsx": () => import("./../../../src/pages/investment-highlights.tsx" /* webpackChunkName: "component---src-pages-investment-highlights-tsx" */),
  "component---src-pages-meet-the-team-tsx": () => import("./../../../src/pages/meet-the-team.tsx" /* webpackChunkName: "component---src-pages-meet-the-team-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-reports-and-media-tsx": () => import("./../../../src/pages/reports-and-media.tsx" /* webpackChunkName: "component---src-pages-reports-and-media-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-trinidad-and-tobago-tsx": () => import("./../../../src/pages/trinidad-and-tobago.tsx" /* webpackChunkName: "component---src-pages-trinidad-and-tobago-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-publication-tsx": () => import("./../../../src/templates/publication.tsx" /* webpackChunkName: "component---src-templates-publication-tsx" */)
}

