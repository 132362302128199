import './src/styles/global.css'
import * as React from 'react'
import { NextUIProvider } from '@nextui-org/react'

// import ReactDOM from 'react-dom/client'

// export const replaceHydrateFunction = () => {
//   return (element, container) => {
//     const root = ReactDOM.createRoot(container)
//     root.render(element)

//   }
// }

export const wrapRootElement = ({ element }) => {
  return <NextUIProvider>{element}</NextUIProvider>
}
